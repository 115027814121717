import { routes } from "./config";
import { Router } from "./components";
import { BrowserRouter } from "react-router-dom";
import { setupStore } from "./store/store";
import { Provider } from "react-redux";
import "./styles/style.scss";
import { Toaster } from "./components/ui/toaster";

const store = setupStore();

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Router routes={routes} />
        <Toaster />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
