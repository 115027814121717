import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import styles from "./Select.module.scss";
import classNamesBind from "classnames/bind";
import { IListItem, SelectProps, SelectListProps } from "./Select.d";
import { Icon } from "../Icon";

const cx = classNamesBind.bind(styles);

const SelectList: FC<SelectListProps.Props> = (props) => {
  const { className, list, handleChange, active } = props;

  const handleClick = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    item: IListItem
  ) => {
    e.stopPropagation();

    handleChange(item);
  };

  return (
    <div
      className={cx("listWrapper", className)}
      style={styles}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={cx("listInner")}>
        {list &&
          list.map((l) => (
            <span
              key={`list-item-${l.id}`}
              className={cx("listItem", {
                active: active && active.id === l.id,
              })}
              onClick={(e) => handleClick(e, l)}
            >
              {l.title}
            </span>
          ))}
      </div>
    </div>
  );
};

export const Select: FC<SelectProps.Props> = (props) => {
  const {
    className,
    list,
    placeholder,
    value,
    loading,
    handleChange,
    label,
    additionalStr,
    customValue,
  } = props;

  const wrapper = useRef<HTMLDivElement>(null);

  const [isActive, setIsActive] = useState(false);
  const [activeValue, setActiveValue] = useState<IListItem | null>(null);

  const handleClick = (isActive: boolean) => {
    setIsActive(!isActive);
  };

  const setNewActiveValue = (item: IListItem) => {
    setIsActive(false);
    setActiveValue(item);

    handleChange && handleChange(item);
  };

  useEffect(() => {
    if (!!customValue === false) {
      setActiveValue(list.filter((l) => l.id === value)[0] || null);
      return;
    }

    setActiveValue(list.filter((l) => l.id === customValue)[0] || null);
  }, [customValue, list]);

  useEffect(() => {
    if (activeValue || !value) return;

    const newActiveValue = list.filter((l) => l.id === value)[0] || null;

    setActiveValue(newActiveValue);
    handleChange && handleChange(newActiveValue);
  }, [activeValue, handleChange, value, list]);

  const handleOutsideClick = useCallback(
    (e: Event) => {
      if (!wrapper.current) return;

      if (e.composedPath().indexOf(wrapper.current) === -1) {
        handleClick(true);
      }
    },
    [wrapper]
  );

  useEffect(() => {
    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <div className={cx(className)}>
      {label && <div className={cx("label")}>{label}</div>}
      <div
        ref={wrapper}
        className={cx("wrapper", { loading, isActive })}
        onClick={() => handleClick(isActive)}
      >
        <span className={cx({ placeholder })}>
          {!activeValue && placeholder}
          {activeValue && (additionalStr || "") + activeValue.title}
        </span>
        <Icon
          icon="chevron"
          className={cx("icon")}
          direction={!isActive ? "down" : "up"}
        />
        {isActive && (
          <SelectList
            list={list}
            handleChange={setNewActiveValue}
            active={activeValue}
          />
        )}
      </div>
    </div>
  );
};

export default Select;
