import React, { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { URLS } from "../config";
import { useAppSelector } from "../hooks/useAppDispatch";
import { selectUserAuthorized } from "../store/auth-slice";

interface IAuthRequired {
  children: React.ReactNode;
}

export const AuthRequired: FC<IAuthRequired> = (props) => {
  const { children } = props;

  const location = useLocation();
  const isAuth = useAppSelector(selectUserAuthorized);

  if (!isAuth) {
    return <Navigate to={URLS.LOGIN_PAGE} state={{ from: location }} />;
  }

  return <>{children}</>;
};
