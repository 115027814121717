import { createSelector, createSlice } from "@reduxjs/toolkit";
import { organizationAPI } from "./services/OrganizationService";
import { RootState } from "./store";

interface IInitialState {
  _inited?: boolean;
  authorized: boolean;
}

const initialState = {
  _inited: false,
  authorized: false,
  steps: {},
} as IInitialState;

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initAuthData: (state) => {
      state._inited = true;
    },
    logIn: (state) => {
      state.authorized = true;
    },
    logOut: (state) => {
      state.authorized = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        organizationAPI.endpoints.getOrganizations.matchFulfilled,
        (state) => {
          state._inited = true;
          state.authorized = true;
        }
      )
      .addMatcher(
        organizationAPI.endpoints.getOrganizations.matchRejected,
        (state) => {
          state.authorized = false;
          state._inited = true;
        }
      );
  },
});

export const { logIn, logOut, initAuthData } = authSlice.actions;

export const selectUserAuthorized = (state: RootState) => state.auth.authorized;
export const selectUserInited = (state: RootState) => state.auth._inited;

export default authSlice.reducer;
