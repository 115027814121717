import React, { FC } from 'react'
import styles from './Button.module.scss'
import classNamesBind from 'classnames/bind'
import { ButtonProps } from './Button.d'

const cx = classNamesBind.bind(styles)

export const Button: FC<ButtonProps.Props> = props => {
	const {
		children,
		className,
		color = 'green',
		fullWidth,
		loading,
		size = 'big',
		disabled,

		icon,
		iconPosition, //
		isLink = false, //
		link,
		witoutBackround = false,
		...otherProps
	} = props

	return (
		<button
			className={cx('button', className, `color-${color}`, `size-${size}`, {
				fullWidth,
				witoutBackround,
				loading,
				disabled,
				isLink,
			})}
			disabled={disabled || loading}
			{...otherProps}
		>
			{children}
			{loading && <span />}
		</button>
	)
}

export default Button
