import React, { FC } from 'react'
import styles from './Icon.module.scss'
import classNamesBind from 'classnames/bind'
import { IconProps } from './Icon.d'
import { Icons } from './Icons'

const cx = classNamesBind.bind(styles)

export const Icon: FC<IconProps.Props> = props => {
	const {
		className,
		color = 'gray',
		size = '28',
		direction = 'down',
		icon = 'organizations',
		onClick
	} = props

	return (
		<div
			className={cx(
				'wrapper',
				`direction-${direction}`,
				`size-${size}`,
				`color-${color}`,
				className,
			)}
			onClick={onClick}
		>
			{Icons[icon]()}
		</div>
	)
}

export default Icon
