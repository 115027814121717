import { createApi } from "@reduxjs/toolkit/dist/query/react";
import {
  ELicenseType,
  ICreateOrganizationBody,
  ICreateOrganizationResponse,
  IDomain,
  IEmailSettings,
  IEmployee,
  IOrganization,
  ISMTPSettings,
  UUID,
} from "../../types/IOrganization";
import { baseQueryWithReauth } from "../helper";

export const organizationAPI = createApi({
  reducerPath: "organizationAPI",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "organizations",
    "domains",
    "emails",
    "smtp",
    "employees",
    "organization",
  ],

  endpoints: (build) => ({
    getOrganizations: build.query<IOrganization[], void>({
      query: () => ({
        url: `/admin/organizations`,
      }),
      transformResponse: (response: { data: any[] }, meta, arg) =>
        response.data,
      providesTags: (result) => ["organizations"],
    }),
    getOrganization: build.query<IOrganization, UUID>({
      query: (id) => ({
        url: `/admin/organizations/${id}`,
      }),
      providesTags: (result) => ["organization"],
    }),
    getConfigDomains: build.query<IDomain[], void>({
      query: () => ({
        url: `/admin/domains`,
      }),
      transformResponse: (response: { data: any[] }, meta, arg) =>
        response.data,
      providesTags: (result) => ["domains"],
    }),
    getEmailSettings: build.query<IEmailSettings[], void>({
      query: () => ({
        url: `/admin/email_settings`,
      }),
      transformResponse: (response: { data: any[] }, meta, arg) =>
        response.data,
      providesTags: (result) => ["emails"],
    }),
    getSMTPSettings: build.query<ISMTPSettings[], void>({
      query: () => ({
        url: `/admin/smtp_settings`,
      }),
      transformResponse: (response: { data: any[] }, meta, arg) =>
        response.data,
      providesTags: (result) => ["smtp"],
    }),
    getEmployees: build.query<
      IEmployee[],
      { id: UUID; limit: number; offset: number }
    >({
      query: ({ id, limit, offset }) => ({
        url: `/admin/organizations/${id}/users?offset=${offset}&limit=${limit}`,
      }),
      transformResponse: (response: { data: any[] }, meta, arg) =>
        response.data,
      providesTags: (result) => ["employees"],
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${queryArgs.id}-${endpointName}`;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.offset === 0) {
          currentCache.length = 50;
          currentCache = newItems;
        } else {
          currentCache.push(...newItems);
        }
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    reinviteSelected: build.mutation<void, string[]>({
      query: (emails) => ({
        url: `/admin/reinvite`,
        body: { emails },
        method: "POST",
      }),
    }),
    reinviteAll: build.mutation<void, UUID>({
      query: (id) => ({
        url: `/admin/organizations/${id}/invite`,
        method: "POST",
      }),
    }),
    createOrganization: build.mutation<
      ICreateOrganizationResponse,
      ICreateOrganizationBody
    >({
      query: (body) => {
        if (body.license_type !== ELicenseType.demo) {
          return {
            url: `/admin/organizations`,
            body,
            method: "POST",
          };
        }

        delete body.starts_at;
        delete body.users_limit;

        return {
          url: `/admin/organizations/demo`,
          body,
          method: "POST",
        };
      },
      invalidatesTags: ["organizations"],
    }),
    editOrganization: build.mutation<
      ICreateOrganizationResponse,
      { body: Omit<ICreateOrganizationBody, "operator_email">; id: UUID }
    >({
      query: ({ body, id }) => {
        if (body.license_type !== ELicenseType.demo) {
          return {
            url: `/admin/organizations/${id}`,
            body,
            method: "PATCH",
          };
        }

        delete body.starts_at;
        // delete body.users_limit

        return {
          url: `/admin/organizations/${id}`,
          body,
          method: "PATCH",
        };
      },
      invalidatesTags: ["organizations", "organization"],
    }),
    deleteOrganization: build.mutation<void, UUID>({
      query: (id) => ({
        url: `/admin/organizations/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["organizations"],
    }),
  }),
});

export const {
  useGetOrganizationsQuery,
  useCreateOrganizationMutation,
  useDeleteOrganizationMutation,
  useGetConfigDomainsQuery,
  useGetEmailSettingsQuery,
  useGetSMTPSettingsQuery,
  useGetOrganizationQuery,
  useLazyGetOrganizationsQuery,
  useEditOrganizationMutation,
  useGetEmployeesQuery,
  useReinviteAllMutation,
  useReinviteSelectedMutation,
} = organizationAPI;
