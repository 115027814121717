/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReauth } from "../helper";
import { UUID } from "../../types/IOrganization";
import { ICourse, IScormCourse } from "../../types/IContent";
import {
  IArchiveModuleCoursesRequest,
  IArchiveModuleCoursesResponse,
  IArchiveModuleRequest,
  IArchiveModuleSourceUpdateRequest,
  IArchiveModuleUpdateRequest,
  IArchiveModulesResponse,
  IArchiveScormCourseUpdateRequest,
  IArchiveScromCoursesRequest,
  IArchiveScromCoursesResponse,
} from "../../types/IArchive";

const REFETCH_DELAY = 1200;

export const coursesAPI = createApi({
  reducerPath: "coursesAPI",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "courses",
    "archive-modules",
    "modules",
    "archive-courses",
    "scrom-course-by-id",
    "course-by-id",
    "scorm-courses",
    "archive-scorm-courses",
  ],
  endpoints: (build) => ({
    // Модульные
    getCourses: build.query<ICourse[], void>({
      query: () => ({
        url: `/library/courses`,
      }),
      transformResponse: (response: { data: ICourse[] }) => response.data,
      providesTags: ["courses"],
    }),
    getCourse: build.query<ICourse, UUID>({
      query: (id) => ({
        url: `/library/courses/${id}`,
      }),
      providesTags: ["course-by-id"],
    }),
    putCourse: build.mutation<
      ResponseWithNotification<ICourse>,
      { body: FormData; id: UUID }
    >({
      query: ({ body, id }) => ({
        url: `/library/courses/${id}`,
        method: "PUT",
        body,
        formData: true,
      }),
      //! Задержка, чтобы на беке усели обновиться данные
      // Нужно добавить обновиление курса по id
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        setTimeout(() => {
          dispatch(coursesAPI.util.invalidateTags(["courses", "course-by-id"]));
        }, REFETCH_DELAY);
      },
      transformResponse: (response: ICourse) => ({
        ...response,
        notificationTip: "Курс успешно обновлен!",
      }),
    }),
    postCourse: build.mutation<ResponseWithNotification<ICourse>, FormData>({
      query: (body) => ({
        url: `/library/courses`,
        method: "POST",
        body,
        formData: true,
      }),
      invalidatesTags: ["courses"],
      transformResponse: (response: ICourse) => ({
        ...response,
        notificationTip: "Курс успешно создан!",
      }),
    }),
    deleteCourse: build.mutation<ResponseWithNotification<void>, UUID>({
      query: (id) => ({
        url: `/library/courses/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["courses"],
      transformResponse: () => ({
        notificationTip: "Курс успешно удален!",
      }),
    }),
    // Скорм
    getScormCourses: build.query<ICourse[], boolean>({
      query: (tested) => ({
        url: `/scorm/courses/organization`,
        params: { tested },
      }),
      transformResponse: (response: { data: ICourse[] }) => response.data,
      providesTags: ["scorm-courses"],
    }),
    getScormCourse: build.query<IScormCourse, UUID>({
      query: (id) => ({
        url: `/scorm/courses/${id}`,
      }),
      providesTags: ["scrom-course-by-id"],
    }),
    postScormCourse: build.mutation<ResponseWithNotification<any>, FormData>({
      query: (body) => ({
        url: `/scorm/courses`,
        method: "POST",
        body,
        formData: true,
      }),
      invalidatesTags: ["scorm-courses"],
      transformResponse: (response: any) => ({
        ...response,
        notificationTip: "Курс успешно создан!",
      }),
    }),
    patchScormCourse: build.mutation<
      ResponseWithNotification<any>,
      { body: FormData; id: UUID }
    >({
      query: ({ body, id }) => ({
        url: `/scorm/courses/${id}`,
        method: "PATCH",
        body,
        formData: true,
      }),
      //! Задержка, чтобы на беке усели обновиться данные
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        setTimeout(() => {
          dispatch(
            coursesAPI.util.invalidateTags([
              "scrom-course-by-id",
              "scorm-courses",
            ])
          );
        }, REFETCH_DELAY);
      },
      transformResponse: (response: any) => ({
        ...response,
        notificationTip: "Курс успешно обновлен!",
      }),
    }),
    deleteScormCourse: build.mutation<ResponseWithNotification<void>, UUID>({
      query: (id) => ({
        url: `/scorm/courses/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["scorm-courses"],
      transformResponse: () => ({
        notificationTip: "Курс успешно удален!",
      }),
    }),
    // TEMPTODO-889 - перенесте в api сотрудника
    // Назначенные курсы
    getArchiveScromCourses: build.query<
      IArchiveScromCoursesResponse,
      IArchiveScromCoursesRequest
    >({
      query: (params) => ({
        url: "admin/content/scorm/courses/archived",
        method: "GET",
        params,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }),
      providesTags: ["archive-scorm-courses"],
    }),
    getArchiveModuleCourses: build.query<
      IArchiveModuleCoursesResponse,
      IArchiveModuleCoursesRequest
    >({
      query: (params) => ({
        url: "admin/content/library/courses/archived",
        method: "GET",
        params,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }),
      providesTags: ["archive-courses"],
    }),

    updateArchiveModuleSource: build.mutation<
      void,
      { id: string; body: IArchiveModuleSourceUpdateRequest }
    >({
      query: ({ id, body }) => ({
        url: `/admin/content/library/courses/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["archive-courses", "courses"],
    }),
    updateArchiveScormCourse: build.mutation<
      void,
      { id: string; body: IArchiveScormCourseUpdateRequest }
    >({
      query: ({ id, body }) => ({
        url: `/admin/content/scorm/courses/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["archive-scorm-courses", "scorm-courses"],
    }),
  }),
});

export const {
  // Модульные
  useGetCoursesQuery,
  useGetScormCoursesQuery,
  usePutCourseMutation,
  usePostCourseMutation,
  useGetCourseQuery,
  // Скорм
  useGetScormCourseQuery,
  usePostScormCourseMutation,
  usePatchScormCourseMutation,
  useDeleteCourseMutation,
  useDeleteScormCourseMutation,
  // Архивы
  useGetArchiveScromCoursesQuery,
  useGetArchiveModuleCoursesQuery,
  useUpdateArchiveModuleSourceMutation,
  useUpdateArchiveScormCourseMutation,
} = coursesAPI;
