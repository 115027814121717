import React, { FC } from 'react'
import styles from './PageTitle.module.scss'
import classNamesBind from 'classnames/bind'
import { PageTitleProps } from './PageTitle.d'

const cx = classNamesBind.bind(styles)

export const PageTitle: FC<PageTitleProps.Props> = props => {
    const { className, children } = props

    return <div className={cx('wrapper', className)}>{children}</div>
}

export default PageTitle