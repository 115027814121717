import { createApi } from "@reduxjs/toolkit/dist/query/react";

import {
  ICreateQuestionBody,
  IEditModuleBody,
  IModule,
  IQuestionWithAnswers,
  IQuestionWithAnswersEditBody,
  ISlide,
  ISlideFromPPTXResponse,
  TModuleWithSlidesAndQuestion,
} from "../../types/modules";
import { baseQueryWithReauth } from "../helper";
import {
  IArchiveModuleRequest,
  IArchiveModuleUpdateRequest,
  IArchiveModulesResponse,
} from "../../types/IArchive";

export const moduleAPI = createApi({
  reducerPath: "moduleAPI",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "modules",
    "archive-modules",
    "module",
    "slides",
    "questions",
    "question",
    "courses",
  ],
  endpoints: (build) => ({
    getModules: build.query<IModule[], void>({
      query: () => ({
        url: `/library/modules`,
      }),
      transformResponse: (response: { data: IModule[] }) => response.data,
      providesTags: ["modules"],
    }),
    getArchiveModules: build.query<
      IArchiveModulesResponse,
      IArchiveModuleRequest
    >({
      query: (params) => ({
        url: "admin/content/library/modules/archived",
        method: "GET",
        params,
      }),
      providesTags: ["archive-modules"],
    }),
    getModule: build.query<TModuleWithSlidesAndQuestion, UUID>({
      query: (id) => ({
        url: `/library/modules/${id}?with_slides=true&with_questions=true&`,
      }),
      providesTags: ["module"],
    }),
    deleteModule: build.mutation<ResponseWithNotification<void>, UUID>({
      query: (id) => {
        return {
          url: `/library/modules/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["modules", "archive-modules"],
      transformResponse: () => ({
        notificationTip: "Модуль успешно удален!",
      }),
    }),
    updateArchiveModule: build.mutation<
      void,
      { id: string; body: IArchiveModuleUpdateRequest }
    >({
      query: ({ id, body }) => ({
        url: `/admin/content/library/modules/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["archive-modules", "modules"],
    }),
    copyModule: build.mutation<ResponseWithNotification<IModule>, UUID>({
      query: (id) => {
        return {
          url: `/library/modules/${id}/duplicate`,
          method: "POST",
        };
      },
      invalidatesTags: ["modules"],
      transformResponse: (response: IModule) => ({
        ...response,
        notificationTip: "Модуль успешно скопирован!",
      }),
    }),
    createModule: build.mutation<
      ResponseWithNotification<IModule>,
      { title: string; description?: string }
    >({
      query: (body) => {
        return {
          url: `/library/modules`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["modules"],
      transformResponse: (response: IModule) => ({
        ...response,
        notificationTip: "Модуль успешно создан!",
      }),
    }),
    editModule: build.mutation<
      ResponseWithNotification<IModule>,
      IEditModuleBody
    >({
      query: ({ id, ...body }) => {
        return {
          url: `/library/modules/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["module", "modules"],
      transformResponse: (response: IModule) => ({
        ...response,
        notificationTip: "Модуль успешно обновлен!",
      }),
    }),
    getSlides: build.query<ISlide[], UUID>({
      query: (id) => ({
        url: `/library/modules/${id}/slides`,
      }),
      transformResponse: (response: { data: ISlide[] }) =>
        response.data.sort((a, b) => a.order_id - b.order_id),
      providesTags: ["slides"],
    }),
    deleteAudioSlide: build.mutation<
      ResponseWithNotification<void>,
      { slideId: UUID; moduleId: UUID }
    >({
      query: ({ slideId, moduleId }) => ({
        url: `/library/modules/${moduleId}/slides/${slideId}/audio`,
        method: "DELETE",
      }),
      invalidatesTags: ["slides"],
      transformResponse: () => ({
        notificationTip: "Аудио успешно удалено!",
      }),
    }),
    createSlide: build.mutation<
      ResponseWithNotification<{ id: UUID }>,
      { id: UUID; body?: FormData }
    >({
      query: ({ id, body }) => {
        return {
          url: `/library/modules/${id}/slides`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["slides"],
      transformResponse: (response: { id: UUID }) => ({
        ...response,
        notificationTip: "Слайд успешно создан!",
      }),
    }),
    editSlide: build.mutation<
      ResponseWithNotification<IModule>,
      { moduleId: UUID; id: UUID; body: FormData }
    >({
      query: ({ moduleId, id, body }) => {
        return {
          url: `/library/modules/${moduleId}/slides/${id}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: ["slides"],
      transformResponse: (response: IModule) => ({
        ...response,
        notificationTip: "Слайд успешно обновлен!",
      }),
    }),
    updatePptxModule: build.mutation<
      ResponseWithNotification<IModule>,
      { module_id: UUID; body: FormData }
    >({
      query: ({ module_id, body }) => {
        return {
          url: `/admin/content/library/modules/${module_id}/pptx`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: ["slides"],
      transformResponse: (response: IModule) => ({
        ...response,
        notificationTip: "Слайд успешно обновлен!",
      }),
    }),
    deleteSlide: build.mutation<
      ResponseWithNotification<void>,
      { id: UUID; moduleId: UUID }
    >({
      query: ({ id, moduleId }) => {
        return {
          url: `/library/modules/${moduleId}/slides/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["slides"],
      transformResponse: () => ({
        notificationTip: "Слайд успешно удален!",
      }),
    }),
    createSlidesFromPptx: build.mutation<
      ISlideFromPPTXResponse,
      { id: UUID; body: FormData }
    >({
      query: ({ id, body }) => {
        return {
          url: `/library/modules/${id}/slides/file`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["slides"],
    }),
    swapSlides: build.mutation<
      ResponseWithNotification<void>,
      { id: UUID; body: { slide_id: UUID; order_id: number }[] }
    >({
      query: ({ id, body }) => {
        return {
          url: `/library/modules/${id}/slides/swap`,
          method: "PATCH",
          body,
        };
      },
      transformResponse: (response: { slide_id: UUID; order_id: number }[]) => {
        return {
          ...response,
          notificationTip: "Слайд успешно обновлен!",
        };
      },
      invalidatesTags: ["slides"],
    }),
    getQuestions: build.query<IQuestionWithAnswers[], UUID>({
      query: (id) => ({
        url: `/library/modules/${id}/questions`,
      }),
      transformResponse: (response: { data: IQuestionWithAnswers[] }) =>
        response.data,
      providesTags: ["questions"],
    }),
    deleteQuestion: build.mutation<
      ResponseWithNotification<void>,
      { id: UUID; moduleId: UUID }
    >({
      query: ({ id, moduleId }) => {
        return {
          url: `/library/modules/${moduleId}/questions/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["questions", "module"],
      transformResponse: () => ({
        notificationTip: "Вопрос успешно удален!",
      }),
    }),
    createQuestuinFromExcel: build.mutation<
      ResponseWithNotification<ISlide[]>,
      { id: UUID; body: FormData }
    >({
      query: ({ id, body }) => {
        return {
          url: `/library/modules/${id}/quastions/file`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["questions", "module"],
      transformResponse: (response: ISlide[]) => ({
        ...response,
        notificationTip: "Вопросы успешно загружены!",
      }),
    }),
    createQuestion: build.mutation<
      ResponseWithNotification<ISlide[]>,
      { moduleId: UUID; body: ICreateQuestionBody }
    >({
      query: ({ moduleId, body }) => {
        return {
          url: `/library/modules/${moduleId}/questions`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["questions", "module"],
      transformResponse: (response: ISlide[]) => ({
        ...response,
        notificationTip: "Вопрос успешно создан!",
      }),
    }),
    getQuestion: build.query<
      IQuestionWithAnswers,
      { id: UUID; moduleId: UUID }
    >({
      query: ({ moduleId, id }) => ({
        url: `/library/modules/${moduleId}/questions/${id}`,
      }),
      providesTags: ["question"],
    }),
    editQuestion: build.mutation<
      ResponseWithNotification<void>,
      { id: UUID; moduleId: UUID; body: IQuestionWithAnswersEditBody }
    >({
      query: ({ id, moduleId, body }) => {
        return {
          url: `/library/modules/${moduleId}/questions/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["question", "questions", "module"],
      transformResponse: () => ({
        notificationTip: "Вопрос успешно обновлен!",
      }),
    }),
  }),
});

export const {
  useGetModulesQuery,
  useGetArchiveModulesQuery,
  useUpdateArchiveModuleMutation,
  useGetModuleQuery,
  useDeleteAudioSlideMutation,
  useDeleteModuleMutation,
  useCopyModuleMutation,
  useDeleteQuestionMutation,
  useDeleteSlideMutation,
  useCreateSlidesFromPptxMutation,
  useCreateModuleMutation,
  useGetSlidesQuery,
  useSwapSlidesMutation,
  useEditModuleMutation,
  useGetQuestionsQuery,
  useCreateQuestionMutation,
  useCreateSlideMutation,
  useEditQuestionMutation,
  useGetQuestionQuery,
  useEditSlideMutation,
  useUpdatePptxModuleMutation,
  useCreateQuestuinFromExcelMutation,
} = moduleAPI;
