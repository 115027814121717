import React, { FC } from 'react'
import styles from './BackButton.module.scss'
import classNamesBind from 'classnames/bind'
import { BackButtonProps } from './BackButton.d'
import { Icon } from '../Icon'
import { useNavigate } from 'react-router-dom'

const cx = classNamesBind.bind(styles)

export const BackButton: FC<BackButtonProps.Props> = props => {
	const { className } = props

	const navigate = useNavigate()

	return (
		<div className={cx('wrapper', className)} onClick={() => navigate(-1)}>
			<Icon icon='chevron' direction='left' />
			<span>Назад</span>
		</div>
	)
}

export default BackButton
