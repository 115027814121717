import React, { FC } from 'react'
import styles from './InputFile.module.scss'
import classNamesBind from 'classnames/bind'
import { InputFileProps } from './InputFile.d'

const cx = classNamesBind.bind(styles)

export const InputFile: FC<InputFileProps.Props> = props => {
	const { className, label, accept, onChange, name, disabled, register, ...otherProps } = props

	const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (disabled || !onChange) return

		onChange(e, name || '', e)
	}

	return (
		<>
			{label && <div className={cx('label')}>{label}</div>}
			<label className={cx('wrapper', className)}>
				<input type='file' accept={accept} onChange={_onChange} {...otherProps} {...register} />
			</label>
		</>
	)
}

export default InputFile
