import { FC, Suspense, useMemo } from "react";
import { RouterProps } from "./Router.d";
import { Route, Routes } from "react-router-dom";
import { AuthLayout, PageLoader } from "../";
import { AuthRequired } from "../../hoc";
import { selectUserAuthorized, selectUserInited } from "../../store/auth-slice";
import { useAppSelector } from "../../hooks/useAppDispatch";
import { useGetOrganizationsQuery } from "../../store/services/OrganizationService";


export const Router: FC<RouterProps.Props> = (props) => {
  const { routes } = props;

  const isAuth = useAppSelector(selectUserAuthorized);
  const isInited = useAppSelector(selectUserInited);

  useGetOrganizationsQuery();

  const pages = useMemo(() => {
    return routes.map(({ path, element: Component, needAuth, ...props }) => {
      if (path === "*" && needAuth !== isAuth) {
        return null;
      }

      return (
        <Route
          key={path}
          path={path}
          {...props}
          element={
            <Suspense
              fallback={
                <AuthLayout>
                  <PageLoader />
                </AuthLayout>
              }
            >
              {needAuth ? (
                <AuthRequired>
                  <Component />
                </AuthRequired>
              ) : (
                <Component />
              )}
            </Suspense>
          }
        />
      );
    });
  }, [routes, isAuth]);

  if (!isInited)
    return (
      <AuthLayout>
        <PageLoader />
      </AuthLayout>
    );

  return <Routes>{pages}</Routes>;
};
