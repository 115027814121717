import React, { FC } from 'react'
import styles from './AuthLayout.module.scss'
import classNamesBind from 'classnames/bind'
import { AuthLayoutProps } from './AuthLayout.d'
import { Sidebar } from '../Sidebar'

const cx = classNamesBind.bind(styles)

export const AuthLayout: FC<AuthLayoutProps.Props> = props => {
	const { className, children } = props

	return (
		<div className={cx('wrapper', className)}>
			<div className={cx('header')}></div>
			<Sidebar />
			<div className={cx('inner')}>{children}</div>
		</div>
	)
}

export default AuthLayout
