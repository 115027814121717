import { authAPI } from "./services/AuthService";
import { organizationAPI } from "./services/OrganizationService";
import { brandingAPI } from "./services/BrandingService";
import { domainAPI } from "./services/DomainService";
import { phishingAPI } from "./services/PhishingService";
import { usersAPI } from "./services/UsersService";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth-slice";
import { instructionsAPI } from "./services/InstructionsService";
import { changeLogAPI } from "./services/ChangeLogService";
import { coursesAPI } from "./services/course-service";
import { moduleAPI } from "./services/module-service";
import { tokenAPI } from "./services/TokenService";
import { smtpSettingsAPI } from "./services/SmtpProfiles";

const rootReducer = combineReducers({
  [authAPI.reducerPath]: authAPI.reducer,
  [organizationAPI.reducerPath]: organizationAPI.reducer,
  [brandingAPI.reducerPath]: brandingAPI.reducer,
  [domainAPI.reducerPath]: domainAPI.reducer,
  [smtpSettingsAPI.reducerPath]: smtpSettingsAPI.reducer,
  [phishingAPI.reducerPath]: phishingAPI.reducer,
  [usersAPI.reducerPath]: usersAPI.reducer,
  [instructionsAPI.reducerPath]: instructionsAPI.reducer,
  [changeLogAPI.reducerPath]: changeLogAPI.reducer,
  [coursesAPI.reducerPath]: coursesAPI.reducer,
  [moduleAPI.reducerPath]: moduleAPI.reducer,
  [tokenAPI.reducerPath]: tokenAPI.reducer,
  auth: authReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware()
        .concat(authAPI.middleware)
        .concat(organizationAPI.middleware)
        .concat(brandingAPI.middleware)
        .concat(phishingAPI.middleware)
        .concat(domainAPI.middleware)
        .concat(smtpSettingsAPI.middleware)
        .concat(usersAPI.middleware)
        .concat(changeLogAPI.middleware)
        .concat(coursesAPI.middleware)
        .concat(moduleAPI.middleware)
        .concat(instructionsAPI.middleware)
        .concat(tokenAPI.middleware);
    },
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
