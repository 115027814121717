import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { logIn, logOut } from "./auth-slice";
import { API_DOMAIN } from "../config";

const baseQuery = fetchBaseQuery({
  baseUrl: API_DOMAIN,
  credentials: "include",
  headers: {
    "X-Referer": window.location.href,
  },
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const refreshResult = await baseQuery(
      "auth/oauth2/refresh",
      api,
      extraOptions
    );

    if (refreshResult) {
      api.dispatch(logIn());
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};
