import React, { FC, useState } from "react";
import styles from "./Input.module.scss";
import classNamesBind from "classnames/bind";
import { InputProps } from "./Input.d";
import { useFormContext } from "react-hook-form";

const cx = classNamesBind.bind(styles);

export const Input: FC<InputProps.Props> = (props) => {
  const {
    classNameWrapper,
    className,
    disabled,
    placeholder,
    resize,
    value,
    name,
    error,
    onChange,
    onBlur,
    fullWidth,
    required,
    disabeled,
    label,
    register,
    isSearch = false,
    isFormWrapper = false,
    variant = "classic",
    readOnly = false,
    ...otherProps
  } = props;

  const form = useFormContext();

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled || !onChange) return;

    onChange(e.target.value, name || "", e);
  };

  const _onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!(disabled || !onBlur)) {
      onBlur && onBlur(e);
    }
    register?.onBlur(e);
  };

  const [readonly, setReadonly] = useState(readOnly);

  return (
    <div className={cx("wrapper", classNameWrapper, { isSearch })}>
      {label && (
        <div className={cx("label")}>
          {label} {required && <span>*</span>}
        </div>
      )}
      {isFormWrapper ? (
        <input
          type="text"
          className={cx("input", className, variant, {
            fullWidth,
            error,
            disabled,
          })}
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          value={value}
          {...otherProps}
          {...register}
          autoComplete="off"
          readOnly={readonly}
          onFocus={() => setReadonly(false)}
          {...form?.register(name || "")}
        />
      ) : (
        <input
          type="text"
          // ref={name ? form?.register : undefined}
          className={cx("input", className, variant, {
            fullWidth,
            error,
            disabled,
          })}
          name={name}
          onChange={_onChange}
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          value={value}
          {...otherProps}
          {...register}
          onBlur={_onBlur}
          autoComplete="off"
          readOnly={readonly}
          onFocus={() => setReadonly(false)}
        />
      )}
      {error && <div className={cx("error-text")}>{error}</div>}
    </div>
  );
};

export default Input;
