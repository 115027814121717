import React, { FC } from 'react'
import styles from './PageLoader.module.scss'
import classNamesBind from 'classnames/bind'
import { PageLoaderProps } from './PageLoader.d'

const cx = classNamesBind.bind(styles)

export const PageLoader: FC<PageLoaderProps.Props> = props => {
    const { className } = props

    return <div className={cx('wrapper', className)}>Loading...</div>
}