import { FC } from "react";
import styles from "./Sidebar.module.scss";
import classNamesBind from "classnames/bind";
import { SidebarProps } from "./Sidebar.d";
import { menu } from "../../config";
import { Icon } from "../Icon";
import { NavLink } from "react-router-dom";
import { cn } from "../../helpers/ui";

const cx = classNamesBind.bind(styles);

export const Sidebar: FC<SidebarProps.Props> = (props) => {
  const { className } = props;

  return (
    <div className={cx("flex flex-col gap-5 px-5 py-5", "wrapper", className)}>
      {menu.map((item) => {
        return (
          <NavLink
            to={item.route}
            key={`${item.route}-${item.title}`}
            className={({ isActive }) =>
              cx("menuItem", {
                active: isActive,
              })
            }
          >
            <div className={cn("flex items-center gap-2")}>
              <Icon className={cx("icon")} icon={item.icon} />
              {item.title}
            </div>
          </NavLink>
        );
      })}
    </div>
  );
};

export default Sidebar;
