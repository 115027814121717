import { UUID } from "../types/IOrganization";

export const API_DOMAIN =
  process.env.REACT_APP_API_DOMAIN || "http://localhost:5000";

export const URLS = {
  ORGANIZATIONS_PAGE: "/dashboard/organizations",
  TOKENS_PAGE: "/dashboard/tokens",
  ORGANIZATION_PAGE: "/dashboard/organizations/:id",
  ORGANIZATION_EDIT_PAGE: "/dashboard/organizations/:id/edit",
  ORGANIZATION_SUPERUSERS_PAGE: "/dashboard/organizations/:id/superusers",
  ORGANIZATION_SUPERUSERS_CREATE_PAGE:
    "/dashboard/organizations/:id/superusers/create",
  ORGANIZATION_EMPLOYEES_PAGE: "/dashboard/organizations/:id/employees",
  ORGANIZATION_BRANDING_PAGE: "/dashboard/organizations/:id/branding",
  ORGANIZATION_DOMAINS_PAGE: "/dashboard/organizations/:id/domains",
  ORGANIZATION_USER_DOMAINS_PAGE: "/dashboard/organizations/:id/user-domains",
  ORGANIZATION_DOMAINS_CREATE_PAGE:
    "/dashboard/organizations/:id/domains/create",
  ORGANIZATION_DOMAINS_EDIT_PAGE:
    "/dashboard/organizations/:id/domains/edit/:domainID",
  ORGANIZATION_CREATE_PAGE: "/dashboard/organizations/create",
  BRANDING_PAGE: "/dashboard/branding",
  BRANDING_CREATE_PAGE: "/dashboard/branding/create",
  BRANDING_EDIT_PAGE: "/dashboard/branding/:id",
  DOMAINS_PAGE: "/dashboard/domains",
  DOMAINS_CREATE_PAGE: "/dashboard/domains/create",
  DOMAINS_EDIT_PAGE: "/dashboard/domains/:domainID",
  SMTP_PROFILES_PAGE: "/dashboard/smtp-profiles",
  SMTP_PROFILES_CREATE_PAGE: "/dashboard/smtp-profiles/create",
  SMTP_PROFILES_EDIT_PAGE: "/dashboard/smtp-profiles/:smtpProfileID",
  LOGIN_PAGE: "/login",
  PHISHINGS_PAGE: "/phishing",
  PHISHING_PAGE: "/phishing/:id",
  INSTRUCTION_PAGE: "/instruction",
  TOKEN_PAGE: "/token",
  TOKEN_CREATE_PAGE: "/token/create",
  INSTRUCTION_CREATE_PAGE: "/instruction/create",
  CHANGE_LOG_PAGE: "/change-log",
  CHANGE_LOG_CREATE_PAGE: "/change-log/create",
  CHANGE_LOG_EDIT_PAGE: "/change-log/edit/page",
  ARCHIVE_PAGE: "/archive",
  COURSES_PAGE: "/courses",
  MODULES_PAGE: "/modules",
  PHISHING_TEMPLATES: '/templates',
  PHISHING_TEMPLATE: '/templates/:template_id',
  PHISHING_TEMPLATE_EDIT: '/templates/:template_id/edit',
  PHISHING_TEMPLATE_PAGE_EDIT: '/templates/:template_id/page/:page_id/edit',
  PHISHING_TEMPLATE_PAGE_CREATE: '/templates/:template_id/page/create',
  PHISHING_TEMPLATES_NEW: '/templates/new',
  PHISHING_TEMPLATES_EMAIL_CREATE: '/templates/:template_id/email/create',
  PHISHING_TEMPLATES_EMAIL_EDIT:
    '/templates/:template_id/email/:email_id/edit',
};

export type TURLS = keyof typeof URLS;

export const getUrlWithID = (id: UUID, url: TURLS, name = "id") => {
  const urlsArray = URLS[url].split(`:${name}`);

  return `${urlsArray[0]}${id}${urlsArray[1]}`;
};

export const getStringUrlWithID = (
  id: UUID,
  url: string,
  name: string = "id"
) => {
  const urlsArray = url.split(`:${name}`);

  return `${urlsArray[0]}${id}${urlsArray[1]}`;
};
