import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { ILogin, ILoginResponse } from "../../types/IAuth";
import { baseQueryWithReauth } from "../helper";

export const authAPI = createApi({
  reducerPath: "authAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    login: build.mutation<ILoginResponse, ILogin>({
      query: ({ email, password }) => {
        const body = `grant_type=${encodeURIComponent(
          "password"
        )}&username=${encodeURIComponent(email)}&password=${encodeURIComponent(
          password
        )}`;
        return {
          url: "/auth/oauth2",
          method: "POST",
          body,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };
      },
    }),
  }),
});

export const { useLoginMutation } = authAPI;
