export type UUID = string;

export interface IConfig {
  id: UUID;
  email_settings: IEmailSettings;
  smtp_settings: ISMTPSettings;
  domain: IDomain;
}

export interface IEmailSettings {
  id: UUID;
  name: string;
  support_email: string;
  signature: string;
}

export interface ISMTPSettings {
  id: UUID;
  host: string;
  name: string;
  user: string;
  use_tls: boolean;
  email_from: string;
}

export enum ERole {
  employee = "employee",
  operator = "operator",
  content_manager = "content_manager",
  system_admin = "system_admin",
  super_user = "super_user",
  pdf_service = "pdf_service",
  hidden_superuser = "hidden_superuser",
}

export enum ERiskLevelColor {
  RED = "RED",
  GREEN = "GREEN",
  YELLOW = "YELLOW",
  BLUE = "BLUE",
}

export enum ERiskLevelGroup {
  AHEAD = "AHEAD",
  NORMAL = "NORMAL",
  BEHIND = "BEHIND",
  FAR_BEHIND = "FAR_BEHIND",
}

export interface IEmployee {
  id: UUID;
  created_at: string;
  updated_at: string;
  email: string;
  organization: {
    id: UUID;
    title: string;
    license_type: ELicenseType;
  };
  first_name: string;
  last_name: string;
  middle_name: string;
  department: {
    id: UUID;
    title: string;
    users_count: number;
    users_count_with_nested: number;
  };
  position: string;
  phone: string;
  is_registered: boolean;
  picture: string;
  role: ERole;
  statistics: {
    risk_level: number;
    group: ERiskLevelGroup;
    color: ERiskLevelColor;
  };
}

export interface IDomain {
  id: UUID;
  title: string;
  url: string;
  logo: {
    dark: string;
    light: string;
  };
  theme: {
    id: UUID;
    name: string;
    colors: {
      primary: string;
    };
  };
  forse_saml: boolean;
  use_admin_ad: boolean;
  use_admin_sso: boolean;
  favicon: string | null;
}

export enum ELicenseType {
  "demo" = "demo",
  "full" = "full",
  "mini" = "mini",
  "content" = "content",
  "phishing" = "phishing",
}

export interface ILicense {
  id: UUID;
  type: ELicenseType;
  starts_at: string;
  ends_at: string;
}

export interface IOrganization {
  id: UUID;
  old_id: number;
  title: string;
  users_limit: number;
  config: IConfig;
  departments_count: number;
  users_count: number;
  license: ILicense;
}

export interface ICreateOrganizationBody {
  title: string;
  operator_email: string;
  ends_at: string;
  license_type: ELicenseType;
  email_settings_id: UUID;
  smtp_settings_id: UUID;
  domain_id: UUID;
  starts_at?: string;
  users_limit?: string;
}

export interface ICreateOrganizationResponse {
  id: UUID;
  old_id: number;
  title: string;
  users_limit: number;
  accounts: any[];
}
