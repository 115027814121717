import React, { FC } from 'react'
import styles from './NotAuthLayout.module.scss'
import classNamesBind from 'classnames/bind'
import { NotAuthLayoutProps } from './NotAuthLayout.d'

const cx = classNamesBind.bind(styles)

export const NotAuthLayout: FC<NotAuthLayoutProps.Props> = props => {
    const { className, children } = props

    return <div className={cx('wrapper', className)}>{children}</div>
}

export default NotAuthLayout